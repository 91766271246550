const dataTypes = {
  REQUEST_LOAD: 'REQUEST_LOAD',
  SUCCESS_LOAD: 'SUCCESS_LOAD',
  FAILURE_LOAD: 'FAILURE_LOAD',

  REQUEST_FOCUS: 'REQUEST_FOCUS',
  SUCCESS_FOCUS: 'SUCCESS_FOCUS',
  FAILURE_FOCUS: 'FAILURE_FOCUS',

  REQUEST_CLUSTER: 'REQUEST_CLUSTER',
  SUCCESS_CLUSTER: 'SUCCESS_CLUSTER',
  FAILURE_CLUSTER: 'FAILURE_CLUSTER',

  REQUEST_DELETE: 'REQUEST_DELETE',
  SUCCESS_DELETE: 'SUCCESS_DELETE',
  FAILURE_DELETE: 'FAILURE_DELETE',

  REQUEST_EXPAND: 'REQUEST_EXPAND',
  SUCCESS_EXPAND: 'SUCCESS_EXPAND',
  FAILURE_EXPAND: 'FAILURE_EXPAND',

  REQUEST_FOLD: 'REQUEST_FOLD',
  SUCCESS_FOLD: 'SUCCESS_FOLD',
  FAILURE_FOLD: 'FAILURE_FOLD',

  REQUEST_ABSTRACT: 'REQUEST_ABSTRACT',
  SUCCESS_ABSTRACT: 'SUCCESS_ABSTRACT',
  FAILURE_ABSTRACT: 'FAILURE_ABSTRACT',

  REQUEST_DEFINE: 'REQUEST_DEFINE',
  SUCCESS_DEFINE: 'SUCCESS_DEFINE',
  FAILURE_DEFINE: 'FAILURE_DEFINE',

  SET_PINNED_NODES: 'SET_PINNED_NODES',
  SET_ACTIVE_NODES: 'SET_ACTIVE_NODES',
  SET_ABSTRACT_COUNT: 'SET_ABSTRACT_COUNT',
  SET_DATA_INITIAL_STATE: 'SET_DATA_INITIAL_STATE',

  EXPORT_ORIGIN: 'EXPORT_ORIGIN',
  CLICK_NODE: 'CLICK_NODE',
};

export default dataTypes;
