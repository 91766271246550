/*eslint-disable*/
export const tooltips = {
  "link.strokeLinecap":
    '            <h4>link.strokeLinecap</h4>            <b>type</b>: string | <b>default value</b>: "butt" | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="link-stroke-linecap" href="#link-stroke-linecap"></a> <a target="_blank" href="https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-linecap">stroke-linecap</a>\nThe stroke-linecap attribute defines the shape to be used at the start and end of the link.\nThe stroke-linecap options are:\n  - "butt"\n  - "round"\n  - "square"</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#link-stroke-linecap">see full official documentation</a>        </small>            ',
  "link.strokeDashoffset":
    '            <h4>link.strokeDashoffset</h4>            <b>type</b>: number | <b>default value</b>: 0 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="link-stroke-dashoffset" href="#link-stroke-dashoffset"></a> <a target="_blank" href="https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-dashoffset">stroke-dashoffset</a>\nThe stroke-dashoffset attribute defines an offset on the rendering of the associated dash array.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#link-stroke-dashoffset">see full official documentation</a>        </small>            ',
  "link.strokeDasharray":
    '            <h4>link.strokeDasharray</h4>            <b>type</b>: number | <b>default value</b>: 0 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="link-stroke-dasharray" href="#link-stroke-dasharray"></a> <a target="_blank" href="https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-dasharray">stroke-dasharray</a>\nThe stroke-dasharray attribute defines the pattern of dashes and gaps used to paint the link.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#link-stroke-dasharray">see full official documentation</a>        </small>            ',
  "link.type":
    '            <h4>link.type</h4>            <b>type</b>: string | <b>default value</b>: "STRAIGHT" | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="link-type" href="#link-type"></a> 🔍 the type of line to draw, available types at this point are:\n- "STRAIGHT" <small>(default)</small> - a straight line.\n- "CURVE_SMOOTH" - a slight curve between two nodes\n- "CURVE_FULL" - a semicircumference trajectory unites source and target nodes.\n</br>\n<img src="https://github.com/danielcaldas/react-d3-graph/blob/master/docs/rd3g-bend.gif?raw=true" width=\'400\' height=\'200\'/></div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#link-type">see full official documentation</a>        </small>            ',
  "link.markerWidth":
    '            <h4>link.markerWidth</h4>            <b>type</b>: number | <b>default value</b>: 6 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="link-marker-width" href="#link-marker-width"></a> <a target="_blank" href="https://developer.mozilla.org/en/docs/Web/SVG/Attribute/markerWidth">markerWidth</a>\nproperty for the link arrowhead width. *Note: this property can only be set in the first mount, it does not update dynamically.*</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#link-marker-width">see full official documentation</a>        </small>            ',
  "link.markerHeight":
    '            <h4>link.markerHeight</h4>            <b>type</b>: number | <b>default value</b>: 6 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="link-marker-height" href="#link-marker-height"></a> <a target="_blank" href="https://developer.mozilla.org/en/docs/Web/SVG/Attribute/markerHeight">markerHeight</a>\nproperty for the link arrowhead height. *Note: this property can only be set in the first mount, it does not update dynamically.*</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#link-marker-height">see full official documentation</a>        </small>            ',
  "link.strokeWidth":
    '            <h4>link.strokeWidth</h4>            <b>type</b>: number | <b>default value</b>: 1.5 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="link-stroke-width" href="#link-stroke-width"></a> 🔍 strokeWidth for all links. By default the actual value is obtain by the\nfollowing expression:\n</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#link-stroke-width">see full official documentation</a>        </small>            ',
  "link.semanticStrokeWidth":
    '            <h4>link.semanticStrokeWidth</h4>            <b>type</b>: boolean | <b>default value</b>: false | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="link-semantic-stroke-width" href="#link-semantic-stroke-width"></a> when set to true all links will have\n<i>"semantic width"</i>, this means that the width of the connections will be proportional to the value of each link.\nThis is how link strokeWidth will be calculated:\n</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#link-semantic-stroke-width">see full official documentation</a>        </small>            ',
  "link.renderLabel":
    '            <h4>link.renderLabel</h4>            <b>type</b>: boolean | <b>default value</b>: false | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="link-render-label" href="#link-render-label"></a> when set to true labels will appear along side links in the\ngraph. <b>Note:</b> this will only happen of course if proper label is passed within the link, check also <code>link.labelProperty</code>.\n</br>\n<img src="https://github.com/danielcaldas/react-d3-graph/blob/master/docs/rd3g-link-render-label.png?raw=true" width=\'400\' height=\'200\'/></div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#link-render-label">see full official documentation</a>        </small>            ',
  "link.opacity":
    '            <h4>link.opacity</h4>            <b>type</b>: number | <b>default value</b>: 1 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;">🔍 - <a href="#link-opacity" href=""></a> the default opacity value for links.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#link-opacity">see full official documentation</a>        </small>            ',
  "link.mouseCursor":
    '            <h4>link.mouseCursor</h4>            <b>type</b>: string | <b>default value</b>: "pointer" | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="link-mouse-cursor" href="#link-mouse-cursor"></a> <a target="_blank" href="https://developer.mozilla.org/en/docs/Web/CSS/cursor?v=control">cursor</a>\nproperty for when link is mouse hovered.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#link-mouse-cursor">see full official documentation</a>        </small>            ',
  "link.labelProperty":
    '            <h4>link.labelProperty</h4>            <b>type</b>: string|function | <b>default value</b>: "label" | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="link-label-property" href="#link-label-property"></a> the property that will be rendered as label within some link. Note that</br>\nthis property needs to be passed along the link payload (along side with source and target). This property can also be a function</br>\nthat receives the link itself as argument and returns a custom string, similarly to what happens with <code>node.labelProperty</code>.</br></div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#link-label-property">see full official documentation</a>        </small>            ',
  "link.highlightFontWeight":
    '            <h4>link.highlightFontWeight</h4>            <b>type</b>: string | <b>default value</b>: "normal" | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="link-highlight-font-weight" href="#link-highlight-font-weight"></a> <a target="_blank" href="https://developer.mozilla.org/en/docs/Web/CSS/font-weight?v=control">font-weight</a> in highlighted state.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#link-highlight-font-weight">see full official documentation</a>        </small>            ',
  "link.highlightFontSize":
    '            <h4>link.highlightFontSize</h4>            <b>type</b>: number | <b>default value</b>: 8 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="link-highlight-font-size" href="#link-highlight-font-size"></a> <a target="_blank" href="https://developer.mozilla.org/en-US/docs/Web/CSS/font-size?v=control">font-size</a> in highlighted state.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#link-highlight-font-size">see full official documentation</a>        </small>            ',
  "link.highlightColor":
    '            <h4>link.highlightColor</h4>            <b>type</b>: string | <b>default value</b>: "SAME" | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="link-highlight-color" href="#link-highlight-color"></a> links\' color in highlight state, default being the same color as `link.color`.\n<img src="https://github.com/danielcaldas/react-d3-graph/blob/master/docs/rd3g-bend.gif?raw=true" width=\'400\' height=\'200\'/></div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#link-highlight-color">see full official documentation</a>        </small>            ',
  "link.fontWeight":
    '            <h4>link.fontWeight</h4>            <b>type</b>: string | <b>default value</b>: "normal" | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="link-font-weight" href="#link-font-weight"></a> <a target="_blank" href="https://developer.mozilla.org/en/docs/Web/CSS/font-weight?v=control">font-weight</a>\nproperty for all links\' labels.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#link-font-weight">see full official documentation</a>        </small>            ',
  "link.fontSize":
    '            <h4>link.fontSize</h4>            <b>type</b>: number | <b>default value</b>: 8 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="link-font-size" href="#link-font-size"></a> <a target="_blank" href="https://developer.mozilla.org/en-US/docs/Web/CSS/font-size?v=control">font-size</a>\nproperty for all links\' labels.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#link-font-size">see full official documentation</a>        </small>            ',
  "link.fontColor":
    '            <h4>link.fontColor</h4>            <b>type</b>: string | <b>default value</b>: "black" | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="link-font-color" href="#link-font-color"></a> 🔍 fill color for link\'s <text> svg label.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#link-font-color">see full official documentation</a>        </small>            ',
  "link.color":
    '            <h4>link.color</h4>            <b>type</b>: string | <b>default value</b>: "#d3d3d3" | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="link-color" href="#link-color"></a> 🔍 the color for links\n(from version 1.3.0 this property can be configured at link level). <b>Note:</b> there\'s a current limitation where arrow markers in directed graphs won\'t have the same color as the link. Again this issue\nonly occurs for individually colored links, if links are colored globally through `link.color`\nthis won\'t be an issue <a target="_blank" href="https://github.com/danielcaldas/react-d3-graph/pull/361">#361</a>.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#link-color">see full official documentation</a>        </small>            ',
  "link.breakPoints":
    '            <h4>link.breakPoints</h4>            <b>type</b>: Array.<Object> | <b>default value</b>: [] | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="link-breakpoints" href="#link-breakPoints"></a> 🔍 an array of coordinates, each coordinate indicates a breakpoint\nwhere the link will break its natural flow and link to the next breakpoint in the list. [Here\'s the original feature request](https://github.com/danielcaldas/react-d3-graph/issues/373) it should give you an idea of the capabilities of this feature.\n**Note that** this property can only be defined a link level and **not** through the config object.\n</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#link-break-points">see full official documentation</a>        </small>            ',
  link:
    '            <h4>link</h4>            <b>type</b>: Object | <b>default value</b>: undefined | <b>optional</b>: undefined            <h5>Description</h5>            <div style="max-width: 400px;">link object is explained in the next section. ⬇️\n<h2 id="config-link"><a href="#config-link">#</a> Link level configurations</h2></div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#link">see full official documentation</a>        </small>            ',
  "node.viewGenerator":
    '            <h4>node.viewGenerator</h4>            <b>type</b>: function | <b>default value</b>: null | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="node-view-generator" href="#node-view-generator"></a> 🔍 function that receives a node and returns a JSX view.\n</br></div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#node-view-generator">see full official documentation</a>        </small>            ',
  "node.symbolType":
    '            <h4>node.symbolType</h4>            <b>type</b>: string | <b>default value</b>: "circle" | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="node-symbol-type" href="#node-symbol-type"></a> 🔍 the <a id="node-symbol-type">shape</span> of the node.\nUse the following values under a property <b>type</b> inside each node (nodes may have different types, same as colors):\n  - "circle"\n  - "cross"\n  - "diamond"\n  - "square"\n  - "star"\n  - "triangle"\n  - "wye"\n\n<b>[note]</b> react-d3-graph will map this values to <a target="_blank" href="https://github.com/d3/d3-shape#symbols">d3 symbols</a></div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#node-symbol-type">see full official documentation</a>        </small>            ',
  "node.svg":
    '            <h4>node.svg</h4>            <b>type</b>: string | <b>default value</b>: "" | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="node-svg" href="#node-svg"></a> 🔍 render custom svg for nodes in alternative to <b>node.symbolType</b>. This svg can\nbe provided as a string to either a remote svg resource or for a local one.\n</br>\n<img src="https://github.com/danielcaldas/react-d3-graph/blob/master/docs/rd3g-custom-svg.gif?raw=true" width=\'400\' height=\'200\'/></div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#node-svg">see full official documentation</a>        </small>            ',
  "node.strokeWidth":
    '            <h4>node.strokeWidth</h4>            <b>type</b>: number | <b>default value</b>: 1.5 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="node-stroke-width" href="#node-stroke-width"></a> 🔍 the width of the all node strokes.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#node-stroke-width">see full official documentation</a>        </small>            ',
  "node.strokeColor":
    '            <h4>node.strokeColor</h4>            <b>type</b>: string | <b>default value</b>: "none" | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="node-stroke-color" href="#node-stroke-color"></a> 🔍  this is the stroke color that will be applied to the node if no <b>strokeColor property</b> is found inside the node itself (yes <b>you can pass a property "strokeColor" inside the node and that stroke color will override this default one</b>).</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#node-stroke-color">see full official documentation</a>        </small>            ',
  "node.size":
    '            <h4>node.size</h4>            <b>type</b>: number|Object | <b>default value</b>: 200 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="node-size" href="#node-size"></a> 🔍 defines the size of all nodes. When set to a number, the node will have equal height and width.</br>\nThis can also be an object with a height and width property <b>when using custom nodes</b>.\n\nThe actual node dimensions (in px) rendered on screen will be the size value divided by 10. For example, a node size of 200 will result in a node with a height and width of 20px.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#node-size">see full official documentation</a>        </small>            ',
  "node.renderLabel":
    '            <h4>node.renderLabel</h4>            <b>type</b>: boolean | <b>default value</b>: true | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="node-render-label" href="#node-render-label"></a> 🔍 when set to false no labels will appear along side nodes in the\ngraph.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#node-render-label">see full official documentation</a>        </small>            ',
  "node.opacity":
    '            <h4>node.opacity</h4>            <b>type</b>: number | <b>default value</b>: 1 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="node-opacity" href="#node-opacity"></a> 🔍 by default all nodes will have this opacity value.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#node-opacity">see full official documentation</a>        </small>            ',
  "node.mouseCursor":
    '            <h4>node.mouseCursor</h4>            <b>type</b>: string | <b>default value</b>: "pointer" | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="node-pointer" href="#node-pointer"></a> <a target="_blank" href="https://developer.mozilla.org/en/docs/Web/CSS/cursor?v=control">cursor</a>\nproperty for when some node is mouse hovered.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#node-mouse-cursor">see full official documentation</a>        </small>            ',
  "node.labelProperty":
    '            <h4>node.labelProperty</h4>            <b>type</b>: string|function | <b>default value</b>: "id" | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="node-label-property" href="#node-label-property"></a> this is the node property that will be used in runtime to</br>\nfetch the label content. You just need to add some property (e.g. firstName) to the node payload and then set</br>\nnode.labelProperty to be <b>"firstName"</b>. <b>This can also be a function!</b>, if you pass a function here it will be called</br>\nto obtain the <code>label</code> value on the fly, as a client you will receive all the node information that you passed down into react-d3-graph,</br>\nso the signature of the function would be:\n\nThen you just need to make sure that you pass this function in the config in <code>config.node.labelProperty</code>.\n</br></div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#node-label-property">see full official documentation</a>        </small>            ',
  "node.labelPosition":
    '            <h4>node.labelPosition</h4>            <b>type</b>: string | <b>default value</b>: null | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="node-label-position" href="#node-label-position"></a> 🔍 location to place node label relative to node.\nThe placement options are:\n  - "left"\n  - "right"\n  - "top"\n  - "bottom"\n  - "center"\n\n<b>[note]</b> not specifying a label position will fallback to the original placement scheme of to the right of the node. This is different than the implementation for "right", which has the label shifted very slightly upward compared to the original.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#node-label-position">see full official documentation</a>        </small>            ',
  "node.highlightStrokeWidth":
    '            <h4>node.highlightStrokeWidth</h4>            <b>type</b>: number | <b>default value</b>: "SAME" | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="node-stroke-width" href="#node-stroke-width"></a> strokeWidth in highlighted state.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#node-highlight-stroke-width">see full official documentation</a>        </small>            ',
  "node.highlightStrokeColor":
    '            <h4>node.highlightStrokeColor</h4>            <b>type</b>: string | <b>default value</b>: "SAME" | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="node-stroke-color" href="#node-stroke-color"></a> strokeColor in highlighted state.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#node-highlight-stroke-color">see full official documentation</a>        </small>            ',
  "node.highlightFontWeight":
    '            <h4>node.highlightFontWeight</h4>            <b>type</b>: string | <b>default value</b>: "normal" | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="node-highlight-font-weight" href="#node-highlight-font-weight"></a> fontWeight in highlighted state.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#node-highlight-font-weight">see full official documentation</a>        </small>            ',
  "node.highlightFontSize":
    '            <h4>node.highlightFontSize</h4>            <b>type</b>: number | <b>default value</b>: 8 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="node-highlight-font-size" href="#node-highlight-font-size"></a> 🔍 fontSize in highlighted state.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#node-highlight-font-size">see full official documentation</a>        </small>            ',
  "node.highlightColor":
    '            <h4>node.highlightColor</h4>            <b>type</b>: string | <b>default value</b>: "SAME" | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="node-highlight-color" href="#node-highlight-color"></a> color for all highlighted nodes (use string "SAME" if you\nwant the node to keep its color in highlighted state).</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#node-highlight-color">see full official documentation</a>        </small>            ',
  "node.fontWeight":
    '            <h4>node.fontWeight</h4>            <b>type</b>: string | <b>default value</b>: "normal" | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="node-font-weight" href="#node-font-weight"></a> <a target="_blank" href="https://developer.mozilla.org/en/docs/Web/CSS/font-weight?v=control">font-weight</a>\nproperty for all nodes" labels.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#node-font-weight">see full official documentation</a>        </small>            ',
  "node.fontSize":
    '            <h4>node.fontSize</h4>            <b>type</b>: number | <b>default value</b>: 8 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="node-font-size" href="#node-font-size"></a> 🔍 <a target="_blank" href="https://developer.mozilla.org/en-US/docs/Web/CSS/font-size?v=control">font-size</a>\nproperty for all nodes" labels.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#node-font-size">see full official documentation</a>        </small>            ',
  "node.fontColor":
    '            <h4>node.fontColor</h4>            <b>type</b>: string | <b>default value</b>: "black" | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="node-font-color" href="#node-font-color"></a> 🔍 fill color for node"s <text> svg label.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#node-font-color">see full official documentation</a>        </small>            ',
  "node.color":
    '            <h4>node.color</h4>            <b>type</b>: string | <b>default value</b>: "#d3d3d3" | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="node-color" href="#node-color"></a> 🔍 this is the color that will be applied to the node if no <b>color property</b></br>\nis found inside the node itself (yes <b>you can pass a property "color" inside</b></br>\n<b>the node and that color will override the this default one</b>).</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#node-color">see full official documentation</a>        </small>            ',
  node:
    '            <h4>node</h4>            <b>type</b>: Object | <b>default value</b>: undefined | <b>optional</b>: undefined            <h5>Description</h5>            <div style="max-width: 400px;">node object is explained in next section. ⬇️\n<h2 id="config-node"><a href="#config-node">#</a> Node level configurations</h2></div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#node">see full official documentation</a>        </small>            ',
  "d3.disableLinkForce":
    '            <h4>d3.disableLinkForce</h4>            <b>type</b>: boolean | <b>default value</b>: false | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="d3-disable-link-force" href="#d3-disable-link-force"></a> Completely disables d3 force link and simulation to re-trigger so that one can obtain\nprecise render of node positions as described by the author <a target="_blank" href="https://github.com/antoninklopp">@antoninklopp</a> in <a target="_blank" href="https://github.com/danielcaldas/react-d3-graph/pull/278">the Pull Request description</a>.\n</br></div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#d3-disable-link-force">see full official documentation</a>        </small>            ',
  "d3.linkStrength":
    '            <h4>d3.linkStrength</h4>            <b>type</b>: number | <b>default value</b>: 1 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="d3-link-strength" href="#d3-link-strength"></a> <a target="_blank" href="https://github.com/d3/d3-force#link_strength">see d3-force link.strength</a></div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#d3-link-strength">see full official documentation</a>        </small>            ',
  "d3.linkLength":
    '            <h4>d3.linkLength</h4>            <b>type</b>: number | <b>default value</b>: 100 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="d3-link-length" href="#d3-link-length"></a> the length of each link from the center of the nodes it joins.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#d3-link-length">see full official documentation</a>        </small>            ',
  "d3.gravity":
    '            <h4>d3.gravity</h4>            <b>type</b>: number | <b>default value</b>: -100 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="d3-gravity" href="#d3-gravity"></a> this will define how close nodes are to each other <a target="_blank" href="https://github.com/d3/d3-force#forces">see d3 reference for forces</a>.\n - If value is positive, nodes will attract each other.\n - If value is negative, nodes will repel each other. Most of the times this is what we want, so nodes don"t overlap.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#d3-gravity">see full official documentation</a>        </small>            ',
  "d3.alphaTarget":
    '            <h4>d3.alphaTarget</h4>            <b>type</b>: number | <b>default value</b>: 0.05 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="d3-alpha-target" href="#d3-alpha-target"></a> <a target="_blank" href="https://github.com/d3/d3-force#simulation_alphaTarget">see d3-force simulation.alphaTarget</a></div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#d3-alpha-target">see full official documentation</a>        </small>            ',
  d3:
    '            <h4>d3</h4>            <b>type</b>: Object | <b>default value</b>: undefined | <b>optional</b>: undefined            <h5>Description</h5>            <div style="max-width: 400px;">d3 object is explained in next section. ⬇️\n<h2 id="config-d3"><a href="#config-d3">#</a> d3 level configurations</h2></div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#d3">see full official documentation</a>        </small>            ',
  width:
    '            <h4>width</h4>            <b>type</b>: number | <b>default value</b>: 800 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="width" href="#width"></a> the width of the (svg) area where the graph will be rendered.\n</br></div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#width">see full official documentation</a>        </small>            ',
  staticGraphWithDragAndDrop:
    '            <h4>staticGraphWithDragAndDrop</h4>            <b>type</b>: boolean | <b>default value</b>: undefined | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="static-graph-with-drag-and-drop" href="#static-graph-with-drag-and-drop"></a> exactly the same as above <code>staticGraph</code>, but it will allow users to drag&drop nodes.\n<b>Note</b>: If <code>staticGraph</code> is set to <code>true</code>, then <code>staticGraphWithDragAndDrop</code> will not produce the desired behaviour, make sure\nto set only one of them to <code>true</code>.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#static-graph-with-drag-and-drop">see full official documentation</a>        </small>            ',
  staticGraph:
    '            <h4>staticGraph</h4>            <b>type</b>: boolean | <b>default value</b>: false | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="static-graph" href="#static-graph"></a> when setting this value to true the graph will be completely static, thus\nall forces and drag events upon nodes will produce not effect. Note that, if this value is true the nodes will be\nrendered with the initial provided <b>x and y coordinates</b> (links positions will be automatically set\nfrom the given nodes positions by rd3g), no coordinates will be calculated by rd3g or subjacent d3 modules.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#static-graph">see full official documentation</a>        </small>            ',
  panAndZoom:
    '            <h4>panAndZoom</h4>            <b>type</b>: boolean | <b>default value</b>: false | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="pan-and-zoom" href="#pan-and-zoom"></a> 🚅🚅🚅 pan and zoom effect when performing zoom in the graph,\na similar functionality may be consulted <a target="_blank" href="https://bl.ocks.org/mbostock/2a39a768b1d4bc00a09650edef75ad39">here</a>.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#pan-and-zoom">see full official documentation</a>        </small>            ',
  minZoom:
    '            <h4>minZoom</h4>            <b>type</b>: number | <b>default value</b>: 0.1 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="min-zoom" href="#min-zoom"></a> min zoom that can be performed against the graph.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#min-zoom">see full official documentation</a>        </small>            ',
  maxZoom:
    '            <h4>maxZoom</h4>            <b>type</b>: number | <b>default value</b>: 8 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="max-zoom" href="#max-zoom"></a> max zoom that can be performed against the graph.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#max-zoom">see full official documentation</a>        </small>            ',
  initialZoom:
    '            <h4>initialZoom</h4>            <b>type</b>: number | <b>default value</b>: null | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="max-zoom" href="#initial-zoom"></a> initial zoom that can be set on the graph.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#initial-zoom">see full official documentation</a>        </small>            ',
  highlightOpacity:
    '            <h4>highlightOpacity</h4>            <b>type</b>: number | <b>default value</b>: 1 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="highlight-opacity" href="#highlight-opacity"></a> this value is used to highlight nodes in the network. The lower\nthe value the more the less highlighted nodes will be visible (related to <i>nodeHighlightBehavior</i>).</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#highlight-opacity">see full official documentation</a>        </small>            ',
  highlightDegree:
    '            <h4>highlightDegree</h4>            <b>type</b>: number | <b>default value</b>: 1 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="highlight-degree" href="#highlight-degree"></a> <b>Possible values: 0, 1 or 2</b>. This value represents the range of the\nhighlight behavior when some node is highlighted. If the value is set to <b>0</b> only the selected node will be\nhighlighted. If the value is set to <b>1</b> the selected node and his 1st degree connections will be highlighted. If\nthe value is set to <b>2</b> the selected node will be highlighted as well as the 1st and 2nd common degree connections.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#highlight-degree">see full official documentation</a>        </small>            ',
  linkHighlightBehavior:
    '            <h4>linkHighlightBehavior</h4>            <b>type</b>: boolean | <b>default value</b>: false | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="link-highlight-behavior" href="#link-highlight-behavior"></a> 🚅🚅🚅 when the user mouse hovers some link that link and the correspondent nodes will be highlighted, this is a similar behavior\nto <i>nodeHighlightBehavior</i> but for links <small>(just for historical reference this property was introduced in <a target="_blank" href="https://github.com/danielcaldas/react-d3-graph/releases/tag/1.0.0">v1.0.0</a>)</small>.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#link-highlight-behavior">see full official documentation</a>        </small>            ',
  nodeHighlightBehavior:
    '            <h4>nodeHighlightBehavior</h4>            <b>type</b>: boolean | <b>default value</b>: false | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="node-highlight-behavior" href="#node-highlight-behavior"></a> 🚅🚅🚅 when user mouse hovers a node that node and adjacent common\nconnections will be highlighted (depending on the <i>highlightDegree</i> value). All the remaining nodes and links assume opacity value equal to <b>highlightOpacity</b>.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#node-highlight-behavior">see full official documentation</a>        </small>            ',
  height:
    '            <h4>height</h4>            <b>type</b>: number | <b>default value</b>: 400 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="height" href="#height"></a> the height of the (svg) area where the graph will be rendered.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#height">see full official documentation</a>        </small>            ',
  focusAnimationDuration:
    '            <h4>focusAnimationDuration</h4>            <b>type</b>: number | <b>default value</b>: 0.75 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="focus-animation-duration" href="#focus-animation-duration"></a> duration (in seconds) for the animation that takes place when focusing the graph on a node.</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#focus-animation-duration">see full official documentation</a>        </small>            ',
  freezeAllDragEvents:
    '            <h4>freezeAllDragEvents</h4>            <b>type</b>: boolean | <b>default value</b>: false | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="freeze-all-drag-events" href="#freeze-all-drag-events"></a> Disables manipulation of graph through drag\nand drop. This includes dragging graph elements, panning and zooming. *Note: this property can only be set in the first mount, it does not update dynamically.*</div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#freeze-all-drag-events">see full official documentation</a>        </small>            ',
  focusZoom:
    '            <h4>focusZoom</h4>            <b>type</b>: number | <b>default value</b>: 1 | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="focus-zoom" href="#focus-zoom"></a> zoom that will be applied when the graph view is focused in a node. Its value must be between\n<i>minZoom</i> and <i>maxZoom</i>. If the specified <i>focusZoom</i> is out of this range, <i>minZoom</i> or <i>maxZoom</i> will be applied instead.</br>\n<b>NOTE</b>: This animation is not trigger by default. In order to trigger it you need to pass down to <code>react-d3-graph</code> the\nnode that you want to focus via prop <code>focusedNodeId</code> along side with nodes and links:\n\n\n\n<img src="https://github.com/danielcaldas/react-d3-graph/blob/master/docs/rd3g-zoom-animation.gif?raw=true" width=\'400\' height=\'200\'/></div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#focus-zoom">see full official documentation</a>        </small>            ',
  directed:
    '            <h4>directed</h4>            <b>type</b>: boolean | <b>default value</b>: false | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="directed" href="#directed"></a> This property makes react-d3-graph handle your graph as a directed graph. It will\nout of the box provide the look and feel of a directed graph and add directional semantic to links. You can see a sample in the image below.\n</br>\n<img src="https://github.com/danielcaldas/react-d3-graph/blob/master/docs/rd3g-directed.gif?raw=true" width=\'400\' height=\'200\'/></div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#directed">see full official documentation</a>        </small>            ',
  collapsible:
    '            <h4>collapsible</h4>            <b>type</b>: boolean | <b>default value</b>: false | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="collapsible" href="#collapsible"></a> 🚅🚅🚅 Allow leaf neighbors nodes to be collapsed (folded), this will allow users to clear the way out and focus on the parts of the graph that really matter.\nTo see an example of this behavior you can access <a href="https://danielcaldas.github.io/react-d3-graph/sandbox/index.html?data=marvel" target="_blank" title="sandbox collapsible example">this sandbox link</a> that has a specific set up to experiment this feature. <b>NOTE</b>: At this moment\nnodes without connections (orphan nodes) are not rendered when this property is activated (see <a target="_blank" href="https://github.com/danielcaldas/react-d3-graph/issues/129">GitHub issue #129</a>).\n</br>\n<img src="https://github.com/danielcaldas/react-d3-graph/blob/master/docs/rd3g-collapsible.gif?raw=true" width=\'400\' height=\'200\'/></div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#collapsible">see full official documentation</a>        </small>            ',
  automaticRearrangeAfterDropNode:
    '            <h4>automaticRearrangeAfterDropNode</h4>            <b>type</b>: boolean | <b>default value</b>: false | <b>optional</b>: true            <h5>Description</h5>            <div style="max-width: 400px;"><a id="automatic-rearrange-after-drop-node" href="#automatic-rearrange-after-drop-node"></a> 🚅🚅🚅 when true performing a node drag and drop should automatically\nrearrange all nodes positions based on new position of dragged node (note: <b>staticGraph</b> should be false). A few notes on this property:\n<ul>\n<li><b>automaticRearrangeAfterDropNode</b> needs to be set before the first graph render. Only the first set value will take effect.</li>\n<li><b>automaticRearrangeAfterDropNode</b> won\'t work together with <b>nodeHighlightBehavior</b> (currently a known limitation, to be address in the future <a href="https://github.com/danielcaldas/react-d3-graph/issues/261" target="_blank">GitHub issue #261</a>).</li>\n</ul></div>                    <small>          <a target="_blank" href="https://danielcaldas.github.io/react-d3-graph/docs/index.html#automatic-rearrange-after-drop-node">see full official documentation</a>        </small>            ',
};