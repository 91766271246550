module.exports = {
  links: [
    {
      source: "Androsynth",
      target: "Chenjesu",
    },
    {
      source: "Androsynth",
      target: "Ilwrath",
    },
    {
      source: "Androsynth",
      target: "Mycon",
    },
    {
      source: "Androsynth",
      target: "Spathi",
    },
    {
      source: "Androsynth",
      target: "Umgah",
    },
    {
      source: "Androsynth",
      target: "VUX",
    },
    {
      source: "Chenjesu",
      target: "Mycon",
    },
    {
      source: "Chenjesu",
      target: "Spathi",
    },
    {
      source: "Chenjesu",
      target: "Umgah",
    },
    {
      source: "Chenjesu",
      target: "VUX",
    },
    {
      source: "Ilwrath",
      target: "Spathi",
    },
    {
      source: "Ilwrath",
      target: "Umgah",
    },
    {
      source: "Ilwrath",
      target: "VUX",
    },
    {
      source: "Mycon",
      target: "Umgah",
    },
    {
      source: "Mycon",
      target: "VUX",
    },
    {
      source: "Umgah",
      target: "VUX",
    },
    {
      source: "Androsynth",
      target: "Guardian",
    },
    {
      source: "Chenjesu",
      target: "Broodhmome",
    },
    {
      source: "Ilwrath",
      target: "Avenger",
    },
    {
      source: "Mycon",
      target: "Podship",
    },
    {
      source: "Spathi",
      target: "Eluder",
    },
    {
      source: "Umgah",
      target: "Drone",
    },
    {
      source: "VUX",
      target: "Intruder",
    },
  ],
  nodes: [
    {
      id: "Androsynth",
    },
    {
      id: "Chenjesu",
    },
    {
      id: "Ilwrath",
    },
    {
      id: "Mycon",
    },
    {
      id: "Spathi",
    },
    {
      id: "Umgah",
    },
    {
      id: "VUX",
    },
    {
      id: "Guardian",
      symbolType: "square",
    },
    {
      id: "Broodhmome",
      symbolType: "square",
    },
    {
      id: "Avenger",
      symbolType: "square",
    },
    {
      id: "Podship",
      symbolType: "square",
    },
    {
      id: "Eluder",
      symbolType: "square",
    },
    {
      id: "Drone",
      symbolType: "square",
    },
    {
      id: "Intruder",
      symbolType: "square",
    },
  ],
};
