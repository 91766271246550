const settingsTypes = {
  SET_ZOOM: 'SET_ZOOM',
  SET_HOP: 'SET_HOP',
  SET_DEFINITIONS_NUMBER: 'SET_DEFINITIONS_NUMBER',
  SET_ABSTRACT_TYPE: 'SET_ABSTRACT_TYPE',
  SET_SHOW_STEREOTYPE: 'SET_SHOW_STEREOTYPE',
  SET_SHOW_COLOR: 'SET_SHOW_COLOR',
  SET_SELECTION_COLOR: 'SET_SELECTION_COLOR',
  SET_SETTINGS_INITIAL_STATE: 'SET_SETTINGS_INITIAL_STATE',
};

export default settingsTypes;
